import axios from "axios";
import store from "../../store/index.js";
// 公用的JS方法
const obj = {
    /*获取上传图片必要信息 type图片类型,文件*/
    /* type=>上传的图片类型 只允许 1,2,3,4,5。 1、医生头像 2、病人头像 3、诊所图像 4、患者影像 5、诊所认证图像   */
    //watermark 是水印
    getuploadsign(type, file, watermark, percentCallback) {
        return new Promise((resolve, reject) => {
            axios.get('/getuploadsign', {
                params: {
                    type: type,
                    watermark: watermark
                }
            }).then(res => {
                if (res.code != 1) {
                    return;
                }
                let data = {};
                data.imgServer = res.data.host;
                data.OSSAccessKeyId = res.data.access_id;
                data.policy = res.data.policy;
                data.signature = res.data.signature;
                data.callback = res.data.callback;
                data.path = res.data.dir;
                data.name = res.data.md5_key;
                data.watermark = res.data.watermark
                data.file = file;
                console.log(data)
                obj.uploadImg(data, percentCallback).then(res => {
                    resolve({
                        res: res,
                        url: data.imgServer + '/' + data.path + data.name + data.watermark
                    });
                });
            }).catch(err => {
                reject(err);
            })
        });

    },
    /*上传图片*/
    uploadImg(data, percentCallback) {
        let fd = new FormData();
        // console.log(data.file.size)
        fd.append('Content-Length', Math.round(data.file.size * 100 / 1024) / 100);
        fd.append('OSSAccessKeyId', data.OSSAccessKeyId);
        fd.append('policy', data.policy);
        fd.append('signature', data.signature);
        fd.append('callback', data.callback);
        fd.append('key', data.path + data.name); //文件名字
        fd.append('success_action_status', '200');
        fd.append('file', data.file); //文件必须声明在最后，否则异常
        return new Promise((resolve, reject) => {
            axios.post(data.imgServer, fd, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                transformRequest: function (data) {
                    return data;
                },
                onUploadProgress: function (progressEvent) { //原生获取上传进度的事件
                    progressEvent['name'] = data.file.name;
                    progressEvent['size'] = data.file.size;
                    store.commit('progressInfo', progressEvent);
                    if (progressEvent.lengthComputable) {
                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
                        typeof percentCallback == 'function' && percentCallback(progressEvent);
                    }
                }
            }).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    },
    // 时间格式化
    formatTime(date, val) {
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var second = date.getSeconds();
        month = month < 10 ? "0" + month : month;
        day = day < 10 ? "0" + day : day;
        hour = hour < 10 ? "0" + hour : hour;
        minute = minute < 10 ? "0" + minute : minute;
        second = second < 10 ? "0" + second : second;
        if (!val) {
            return year + "-" + month + "-" + day;
        } else {
            return (
                year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
            );
        }
    }
}

export default {
    install(Vue) {
        Vue.prototype.$getuploadsign = obj.getuploadsign;
        Vue.prototype.$formatTime = obj.formatTime;
    }
};
