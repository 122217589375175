import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  { // 配置默认路由
    path: "/", // 路由地址
    redirect: "/login" // 重定向
  },
  {
    path: "*",
    component: () => import('../views/login/404.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  }, {
    path: '/forgot',
    name: 'forgot',
    component: () => import('../views/login/forgot.vue')
  },
  { // 设计方案，CRM端需要用到
    path: '/stlFile',
    name: 'stlFile',
    component: () => import('../components/stlFile/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home/home.vue'),
    children: [
      {
        path: '/',
        name: 'order',
        meta: { type: 0 },
        component: () => import('../views/order/order.vue')
      },
      {
        path: '/orderDetails',
        name: 'orderDetails',
        meta: { type: 0 },
        component: () => import('../views/order/orderDetails.vue')
      },
      {
        path: '/createOrder',
        name: 'createOrder',
        meta: { type: 0 },
        component: () => import('../views/order/createOrder.vue')
      },
      {
        path: '/waitinglist',
        name: 'waitinglist',
        meta: { type: 0 },
        component: () => import('../views/order/waitinglist.vue')
      },
      {
        path: '/afterSales',
        name: 'afterSales',
        meta: { type: 1 },
        component: () => import('../views/afterSales/afterSales.vue')
      },
      {
        path: '/afterSalesDetails',
        name: 'afterSalesDetails',
        meta: { type: 1 },
        component: () => import('../views/afterSales/afterSalesDetails.vue')
      },
      {
        path: '/freedelivery',
        name: 'freedelivery',
        meta: { type: 2 },
        component: () => import('../views/freedelivery/freedelivery.vue')
      },
      {
        path: '/freedeliveryList',
        name: 'freedeliveryList',
        meta: { type: 2 },
        component: () => import('../views/freedelivery/freedeliveryList.vue')
      },
      {
        path: '/pointsMall',
        name: 'pointsMall',
        meta: { type: 3 },
        component: () => import('../views/pointsMall/pointsMall.vue')
      },
      {
        path: '/detailsCredits',
        name: 'detailsCredits',
        meta: { type: 3 },
        component: () => import('../views/pointsMall/detailsCredits.vue')
      },
      {
        path: '/recordExchange',
        name: 'recordExchange',
        meta: { type: 3 },
        component: () => import('../views/pointsMall/recordExchange.vue')
      },
      {
        path: '/detailsExchange',
        name: 'detailsExchange',
        meta: { type: 3 },
        component: () => import('../views/pointsMall/detailsExchange.vue')
      },
      {
        path: '/exchange',
        name: 'exchange',
        meta: { type: 3 },
        component: () => import('../views/pointsMall/exchange.vue')
      },
      {
        path: '/confirmOrder',
        name: 'confirmOrder',
        meta: { type: 3 },
        component: () => import('../views/pointsMall/confirmOrder.vue')
      },
      {
        path: '/userCenter',
        name: 'userCenter',
        meta: { type: null },
        component: () => import('../views/userCenter/userCenter.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
})

export default router
